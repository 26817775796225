.pt-120 {
    padding-top: 120px;
}
.blog-img {
    overflow: hidden;
}
.blog-img img {
    width: 100%;
    transition: all 0.5s ease-in-out;
}
.blog-meta {
    margin-top: 25px;
    margin-bottom: 7px;
}
.blog-meta > li {
    display: inline-block;
    list-style: none;
    font-size: 13px;
    line-height: 30px;
    position: relative;
    color: #757575;
    font-weight: 600;
    margin-right: 20px;
    text-transform: uppercase;
}
.blog-meta > li > a {
    color: #757575;
    display: inline-block;
}
.blog-content > h3 {
    font-size: 24px;
    color: #0F2041;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 15px;
    font-family: 'Droid Serif';
}
.blog-wrapper > .blog-content > p {
    margin-top: 15px;
}
.blog-wrapper p {
    max-width: 100%;
}
.blog-content > p {
    font-size: 16px;
    line-height: 25px;
    color: #606060;
    margin-bottom: 0;
    font-weight: 400;
    max-width: 355px;
}
ul {
    margin: 0px;
    padding: 0px;
}
.blog-post-list {
    margin: 50px 0;
    margin-top: 42px;
}
.blog-post-list > li {
    display: block;
    list-style: none;
    font-size: 16px;
    color: #606060;
    line-height: 30px;
    margin-bottom: 15px;
    position: relative;
    padding-left: 20px;
}
.blog-post-list > li::before {
    position: absolute;
    content: "\f101";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    z-index: 1;
}
.mb-30 {
    margin-bottom: 30px;
}
.img-full img {
    width: 100%;
}
.mb-55 {
    margin-bottom: 55px;
}
/*blockquote {

    background: #0F2041;
    padding: 60px 130px;
    padding-left: 160px;
    color: #a8a8a8;
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
    position: relative;
    padding-top: 90px;
}
blockquote::before {
    content: "";
    background-image: url(img/icon/blockquote-quote.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 12%;
    top: 27%;
}*/
.blog-wrapper p {
    max-width: 100%;
}
.blog-bottom-content p {
    font-size: 16px;
    color: #353535;
    line-height: 25px;
    margin-bottom: 5px;
}
.mt-60 {
    margin-top: 60px;
}
.blog-author {
    padding-left: 35px;
    text-align: left;
}
.small-title {
    color: #0F2041;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 40px;
    font-size: 18px;
    font-family: 'Droid Serif';
    float: left;
}
.blog-author-box {
    padding: 40px;
    background: #fafafa;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}
.blog-author-img {
    height: 95px;
    width: 95px;
    display: block;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 25px;
}
.blog-author-img img {
    width: 100%;
}
.blog-author-content {
    -webkit-flex-shrink: 100;
    -moz-flex-shrink: 100;
    -ms-flex-negative: 100;
    flex-shrink: 100;
    text-align: left;
}
.blog-author-content h6 {
    line-height: 28px;
    font-size: 16px;
    color: #5f5f5f;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 10px;
}
.blog-author-content p {
    font-size: 16px;
    line-height: 26px;
    color: #353535;
    margin-bottom: 0px;
    max-width: 505px;
}
.common-tag-and-next-prev {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.mt-65 {
    margin-top: 65px;
}
.common-tag, .blog-share {
    display: flex;
    align-items: center;
}
.common-tag > h6, .blog-share > h6 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 700;
    margin-right: 10px;
    font-family: 'Droid Serif';
}
.common-tag ul > li, .blog-share ul > li {
    display: inline-block;
    list-style: none;
    vertical-align: middle;
}
.common-tag ul > li > a, .blog-share ul > li > a {
    font-size: 14px;
    font-weight: 400;
    color: #656565;
    line-height: 25px;
    margin-right: 3px;
}
.common-tag > h6 {
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 10px;
    font-weight: 700;
}
.comment {
    display: flex;
    margin-top: 60px;
}
.blog-author-img {
    height: 95px;
    width: 95px;
    display: block;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 25px;
}
.blog-author-img img {
    width: 100%;
}
.comment-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    flex-shrink: 100;
}
.comment-content-top {
    margin-right: 30px;
    margin-bottom: 10px;
}
.comment-content-top > h6 {
    font-size: 16px;
    font-weight: 700;
    color: #5f5f5f;
    font-family: 'Droid Serif';
}
.comment-content-top > span {
    font-size: 14px;
    color: #a2a2a2;
    line-height: 20px;
}
.comment-content a.reply {
    font-size: 14px;
    color: #C8242F;
    font-weight: 400;
    text-transform: uppercase;
}
.comment-content-bottom {
    flex: 0 0 100%;
}
.comment.reply {
    margin-left: 150px;
}
.comment-box .single-input {
    position: relative;
    margin-top: 50px;
}

.single-input > input, .single-input > textarea {
    font-size: 14px;
    color: #636363;
    line-height: 46px;
    border-bottom: 1px solid #efefef;
}
.sent-btn:hover {
    background: #fff;
    color: #0F2041;
}
.sent-btn {
    display: inline-block;
    height: 45px;
    background: #C8242F;
    padding: 10px 30px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    vertical-align: middle;
    overflow: hidden;
    border: 1px solid #C8242F;
    transition: 0.3s ease-in-out;
}

/* Sidebar CSS */
.blog-sidebar.right-sidebar {
    padding-left: 35px;
    text-align: left;
}
.mb-60 {
    margin-bottom: 60px;
}
.widget-title > h4 {
    font-size: 18px;
    font-weight: 700;
    color: #0F2041;
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 25px;
    display: inline-block;
    font-family: 'Droid Serif';
}
.widget-title > h4::before {
    position: absolute;
    content: "";
    height: 2px;
    width: 50px;
    background-color: #6d6d6d;
    bottom: 0;
    left: 0;
    z-index: 9;
}
.sidebar-form form, .widget-newslatter > form {
    position: relative;
}
.sidebar-form > form > input {
    height: 50px;
    border: 1px solid #f1f1f1;
    font-size: 14px;
    color: #b5b5b5;
    line-height: 30px;
    padding: 10px 25px;
    width: 100%;
    padding-right: 50px;
}

.sidebar-rc-post > ul > li {
    list-style: none;
    overflow: hidden;
    display: block;
    margin-bottom: 30px;
}
.sidebar-rc-post .rc-post-thumb {
    width: 120px;
    height: 100px;
    float: left;
    overflow: hidden;
    /* display: inline-block; */
}
.sidebar-rc-post .rc-post-content {
    margin-left: 140px;
    max-width: 200px;
}
.rc-post-content .widget-date {
    line-height: 1;
    font-size: 12px;
    margin-bottom: 15px;
    font-weight: 500;
}
.rc-post-content > h4 {
    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 0;
}
.rc-post-content > h4 > a:hover {
    color: #C8242F;
}
.rc-post-content > h4 > a {
    text-decoration: none;
    font-family: 'Droid Serif';
}
.widget-categories > ul > li {
    display: block;
    list-style: none;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
    color: #0F2041;
    font-weight: 400;
}
.widget-categories > ul > li::before {
    content: "\eb55";
    font-family: "Icofont";
    vertical-align: middle;
    padding-right: 5px;
    font-size: 22px;
    /* content: url("../../../public/assets/images/right-arrow.png"); */
}
.widget-categories > ul > li > a {
    color: #555151;
    padding-left: 5px;
    display: inline-block;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}
.widget-categories > ul > li > span {
    float: right;
}
.widget-categories > ul > li > a:hover {
    transform: translateX(5px);
    color: #C8242F;
}
.sidebar-tag > li {
    display: inline-block;
    margin-right: 12px;
    padding-bottom: 18px;
}
.sidebar-tag > li > a {
    display: inline-block;
    font-weight: 600;
    color: #0F2041;
    font-size: 14px;
    padding: 0 20px;
    height: 36px;
    line-height: 38px;
    border: 1px solid #cbcbcb;
}
.sidebar-tag > li > a:hover {
    background: #C8242F;
    color: #fff;
    border-color: #C8242F;
}