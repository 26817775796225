.logo{
    padding: 5px 0;
}
.logo-img{
    /* width: 50px; */
    height: auto;
}

.logo a{
    text-decoration: none;
    color: #0D79CF;
}
.logo a:hover{
    text-decoration: none !important;
}
.logo .holder{
    display: inline-flex;
    justify-content: center;
}
.logo a span{
    font-size: 14px;
    margin-top: 3rem;
}