.blog-card{
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}
.mb-30 {
    margin-bottom: 30px;
}
.blog-img {
    overflow: hidden;
}
.blog-img img {
    width: 100%;
    transition: all 0.5s ease-in-out;
}
.blog-meta-data {
    /* margin-top: 25px; */
    /* margin-bottom: 7px; */
}
.blog-meta-data > li, .blog-meta-data > li > a {
    display: inline-block;
    list-style: none;
    font-size: 11px;
    line-height: 30px;
    position: relative;
    color: #757575;
    font-weight: 600;
    margin-right: 20px;
    text-transform: uppercase;
    font-family: 'Mada', sans-serif;
}
.blog-list-content{
    text-align: left;
}
/* .single-blog .blog-list-content .blog-meta-data > li::before { */
.single-blog .blog-list-content > li::before {
    content: "";
    width: 5px;
    height: 1px;
    position: absolute;
    top: 50%;
    right: -14px;
    z-index: 99;
    background: #757575;
}
.blog-list-content > h3 {
    font-size: 24px;
    font-weight: 700;
    /* line-height: 32px;
    margin-bottom: 15px; */
    font-family: 'Droid Serif';
}
.blog-desc {
    /* font-size: 15px;
    color: #606060;
    margin-bottom: 0;
    font-weight: 400;
    max-width: 300px;
    line-height: 18px; */
    
    font-size: 15px;
    color: #606060;
    overflow: hidden;
    max-height: 3.2rem;
    line-height: 1.6rem;
    display: -webkit-box; 
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.blog-desc-nimg {
    /* font-size: 15px;
    color: #606060;
    margin-bottom: 0;
    font-weight: 400;
    max-width: 355px;
    line-height: 18px; */

    font-size: 15px;
    color: #606060;
    overflow: hidden;
    max-height: 3.2rem;
    line-height: 1.6rem;
    display: -webkit-box; 
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.blog-list-content > a {
    font-size: 14px;
    display: inline-block;
    line-height: 30px;
    margin-top: 15px;
    color: #636262;
    font-family: Droid Serif;
    font-weight: 700;
    position: relative;
}
.blog-title-text{
    font-size: 18px;
}
.blog-title-text{
    color: #555151;
}
.blog-title-text:hover {
    color: #C8242F;
    text-decoration: none;
}
.blog-list-content > a > .arrIcon {
    font-size: 18px;
    vertical-align: middle;
}
.single-blog > a {
    outline: medium none;
    color: #434343;
}

@media only screen and (min-width: 200px) and (max-width: 769px) {

    .blog-card{
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        margin-right: 6rem;
        margin-bottom: 1rem;
        border: 0.5px solid #e9e4e4;
    }

}