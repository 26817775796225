.preloader{
    background-color:#f7f7f7;
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:999999;
    -webkit-transition:.6s;
    -o-transition:.6s;
    transition:.6s;
    margin:0 auto
}
.preloader .preloader-circle{
    width:100px;
    height:100px;
    position:relative;
    border-style:solid;
    border-width:1px;
    border-top-color:#C8242F;
    border-bottom-color:transparent;
    border-left-color:transparent;
    border-right-color:transparent;
    z-index:10;
    border-radius:50%;
    -webkit-box-shadow:0 1px 5px 0 rgba(35,181,185,0.15);
    box-shadow:0 1px 5px 0 rgba(35,181,185,0.15);
    background-color:#fff;
    -webkit-animation:zoom 2000ms infinite ease;
    /* animation:zoom 2000ms infinite ease; */
    animation: spin 1s linear infinite;
    -webkit-transition:.6s;
    -o-transition:.6s;
    transition:.6s

}
.preloader .preloader-circle2{
    border-top-color:#0078ff
}
.preloader .preloader-img{
    position:absolute;
    top:50%;
    z-index:200;
    left:0;
    right:0;
    margin:0 auto;
    text-align:center;
    display:inline-block;
    -webkit-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    transform:translateY(-50%);
    padding-top:6px;
    -webkit-transition:.6s;
    -o-transition:.6s;
    transition:.6s
}
.preloader .preloader-img img{
    max-width:55px
}
.preloader .pere-text strong{
    font-weight:800;
    color:#dca73a;
    text-transform:uppercase
}
.loader-image{
    width: auto;
    height: 2rem;
    margin-bottom: 0.6rem;
}
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}