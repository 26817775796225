.about-data-holder{
    text-align: left;
}
.about-data-holder > p{
    font-size: 20px;
}
.about-data-holder > p > strong{
    font-size: 22px;
}
.about-data-holder > ul{
    margin-left: 3rem;
    margin-bottom: 2rem;
}
.about-data-holder > ul > li > strong{
    font-size: 19px;
    
}
.about-data-holder > ul > li{
    font-size: 18px;
}